import React, { ReactNode, useState } from 'react'
import { useDeletePlanningMutation, useGetCompanyPlanningQuery } from '../../service/travelMgrApi'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Planning } from '../../service/type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'primereact/button'
import { faChild, faPersonWalkingLuggage, faRoute } from '@fortawesome/free-solid-svg-icons'
import { Chip } from 'primereact/chip'
import { DataView } from 'primereact/dataview'
import { classNames } from 'primereact/utils'
import { Card } from 'primereact/card'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { Divider } from 'primereact/divider'
import { ScheduleForm } from './ScheduleForm'
import { FullRouteDisplayTemplate, RouteDisplayTemplate } from '../route/RouteDisplayTemplate'
import { OccurenceDisplayTemplate } from './occurence/OccurenceDisplayTemplate'
import { PriceComponent } from './Price'
import { useNavigate } from 'react-router-dom'

export const ScheduleHomePage = () => {

    const {data, error, isLoading, status, isError, isFetching} = useGetCompanyPlanningQuery()
    const [deletePlanningApi] = useDeletePlanningMutation()
    const [visible, setVisible] = useState<boolean>(false)
    const [travelDetailsDialogvisible, setTravelDetailsDialogVisible] = useState<boolean>(false)
    const [selectedPlanning, setSelectedPlanning] = useState<Planning>()

    const navigate = useNavigate();



    const onplanningDeletion = async (planningId : number) => {
      try {
          const result = await deletePlanningApi(planningId).unwrap()
      } catch (error) {
          throw error
      }
  }

    const departureTimesTemplates = (rowData: Planning) => {
        return (
            <div className='flex flex row gap-1 flex-wrap'>
              {[... rowData.times].sort((a, b) => a.localeCompare(b)).map(depTime => <Chip key={depTime} label={depTime} className='bg-primary' style={{color: '#000000'}} pt={{label : {
                className: 'my-chip-margin'
              }}} />)}
            </div>
        );
      };
    
      const emptyPlanning = () => {
        return <div className='flex flex-column align-items-center justify-content-center' style={{height: "80vh"}}>
          <div className='my-2'>
            Aucun programe de voyage
          </div>
          <div className='my-2 flex gap-3'>
            <Button label="Créer un planning" size='small' icon="pi pi-calendar" onClick={() => setVisible(true)}/>
          </div>
        </div>
      }
    

      const routeCardTitle = () => {
        return <div className='flex justify-content-between flex-wrap'>
            <div>Gestion des horaires</div>
            <div className='flex align-items-center justify-content-center gap-4'>
              {header()}
                <Button 
                  size='small' type="button" 
                  className='mt-1' outlined icon="pi pi-plus" 
                  label='Plannifier un voyage' onClick={() => setVisible(true)} 
                  pt={{
                    root: {
                      style: {
                        "paddingTop": ".5rem",
                        "paddingBottom": ".5rem"
                      }
                    }
                  }}/>
            </div>
        </div>
    }

    const header = () => {
      return <div className="flex flex-row-reverse">
          <IconField iconPosition="left">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText placeholder="Search" type="text" className="p-inputtext-sm w-8rem sm:w-auto" pt={{
              root: {
                style: {
                  "borderRadius": "2rem",
                  "paddingTop": ".5rem",
                  "paddingBottom": ".5rem"
                }
              }
            }}/>
          </IconField>
      </div>
    }

      const itemTemplate = (planning: Planning, index: number) => {
        return <div className="col-12 bg-white my-5 border-1 border-gray-200 border-round-xl p-0" key={planning.id}>
            <div className={classNames("flex flex-row hover:bg-primary-50 cursor-pointer")}>
                <div className="bg-gray-200 px-1 font-bold align-content-center border-round-left-xl bg-primary"></div>

                <div className='flex flex-grow-1 py-2 overflow-x-auto common-scroll' onClick={(e) => {navigate("/planning/"+planning.id, {state: {planning: planning}})}}>
                  <FullRouteDisplayTemplate route={planning.route} titleFontSize={2} contentFontSize={1}/>
                  <div style={{color: '#000000'}} className='flex flex-column p-button-warning flex-grow-1 align-content-center justify-content-center align-items-center'>
                    <div className='text-center my-3'>
                      <OccurenceDisplayTemplate occurence={planning.occurrence} />
                    </div>
                    <div>{departureTimesTemplates(planning)}</div>
                  </div>

                  <div className='flex flex-grow-1 flex-column align-content-center justify-content-around align-items-center m-2 '>
                    {planning.prices.map(price => <PriceComponent key={price.priceCategory} price={price} />)}
                  </div>
                </div>
                
                <div className="bg-gray-200 font-bold align-content-center border-round-right-xl">
                  <div className='flex flex-column gap-2'>
                      <Button size='small' icon="pi pi-pencil" text  rounded  aria-label="Filter"  severity='contrast'/>
                      <Button size='small' icon="pi pi-trash" text  rounded aria-label="Filter" severity='contrast' onClick={(e) => {onplanningDeletion(planning.id)}} />
                  </div>
                </div>
            </div>
        </div>   
    }


  const listTemplate = (plannings: Planning[]) : ReactNode[] => {
    return plannings.map((planning, index) => itemTemplate(planning, index))
  }


  return (
    <Card title={routeCardTitle} className='shadow-none' style={{backgroundColor: "transparent"}} pt={{
      content: {
        className: "p-0"
      }}}>
        {(isLoading || isFetching) &&
            <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
                <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s" />
            </div>
        }

        { data && 
          <DataView 
            value={data} 
            listTemplate={listTemplate} 
            /*header={header()}*/
            pt={{
              content: {
                  style: {
                      "backgroundColor": "transparent",
                  }
              },
              paginator: {
                  root: {
                      className: "border-round-xl bg-red-500 text-3xl p-5",
                      style: {
                          "backgroundColor": "red"
                      }
                  }
              }
          }}/>
          }

        {
          data && data.length ==0 &&
          emptyPlanning()
        }
        
        <ScheduleForm visible={visible} setVisible={setVisible} />
        
    </Card>
  )
}
