import { Card } from "primereact/card"
import { CardHeader } from "../../component/shared/CardHeader"
import { Dropdown } from "primereact/dropdown"
import { ReactNode, useCallback, useState } from "react"
import { DetailedTravel, FillRate, Route, Travel } from "../../service/type"
import { FullRouteDisplayTemplate, LightRouteDisplayTemplate, RouteDisplayTemplate } from "../route/RouteDisplayTemplate"
import { useGetAllRouteQuery, useGetDetailedTravelsInPlanningQuery, useGetTravelsInPlanningQuery } from "../../service/travelMgrApi"
import { Calendar } from "primereact/calendar"
import { Nullable } from "primereact/ts-helpers"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ProgressSpinner } from "primereact/progressspinner"
import { atEndOfDay, atStartOfDay, formatDateString, formatTimeString, parseDateString } from "../../DateUtils"
import { Badge } from "primereact/badge"
import { DataView } from "primereact/dataview"
import { Divider } from "primereact/divider"
import { classNames } from "primereact/utils"
import { addDays, isAfter, isBefore, isEqual } from "date-fns"
import { MeterGroup } from "primereact/metergroup"


export const TravelHomePage = () => {

    const { data: routesData, error: routeQError, isLoading: routeQLoading, status: routeQStatus, isError: routeQIsError, isFetching: routeQIsFetching } = useGetAllRouteQuery()
    const { data: travelQData, error: travelQError, isLoading: travelQLoading, status: travelQStatus, isError: travelQIsError, isFetching: travelQIsFetching } = useGetDetailedTravelsInPlanningQuery({planningId: "1"})

    const [selectedRoute, setSelectedRoute] = useState<Route>()
    const [selectedDetailedTravel, setSelectedDetailedTravel] = useState<DetailedTravel>()

    const startDate = new Date()
    const endDate = addDays(new Date(), 7)
    const [dateRange, setDateRange] = useState<Nullable<(Date | null)[]>>([startDate, endDate])

    const fullFill = [
        { label: 'Adulte', color: '#34d399', value: 90 },
        { label: 'Enfant', color: '#fbbf24', value: 10 },
    ]

    const fillRateData = (fillRate: FillRate) => {
        return { label: fillRate.ageCategory.name, color: fillRate.ageCategory.code === 'CHILD' ? '#fbbf24' : '#34d399', value: fillRate.rate }
    }

    const fillRatesData = (fillRates: FillRate[]) => {
        return fillRates.map(fillRate => fillRateData(fillRate))
    }

    const dataToDisplay = useCallback(() => {
        return travelQData ? 
            [...travelQData]
                .filter(detailedTravel => selectedDetailedTravel ? selectedDetailedTravel.travel.route.id === detailedTravel.travel.route.id : true)
                .filter(detailedTravel => dateRange && dateRange.length == 2 ? 
                    (isAfter(parseDateString(detailedTravel.travel.startDate), atStartOfDay(dateRange[0] as Date)) || isEqual(parseDateString(detailedTravel.travel.startDate), atStartOfDay(dateRange[0] as Date))) 
                    && (isBefore(parseDateString(detailedTravel.travel.startDate), atEndOfDay(dateRange[1] as Date)) || isEqual(parseDateString(detailedTravel.travel.startDate), atEndOfDay(dateRange[1] as Date)))
                    :true)
                .sort((a, b) => (a.travel.startDate+a.travel.startTime).localeCompare(b.travel.startDate+b.travel.startTime)) 
            : []
    }, [travelQData, dateRange, selectedRoute])

    const routeItemTemplate = (option: Route) => {
        return (
            <RouteDisplayTemplate route={option} titleFontSize={1} contentFontSize={1} />
        );
    }




    const loader = <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s" />
        </div>
    
    const startDateBody = (rowData: Travel) => {
        return <div>
            <p className="white-space-nowrap overflow-hidden text-overflow-ellipsis text-primary text-lg font-bold">{formatDateString(rowData.startDate)}</p>
            </div>
    }

    const routeBody = (rowData: Travel) => {
        return <div className="">
                <FullRouteDisplayTemplate route={rowData.route} titleFontSize={2} contentFontSize={1}/>
            </div>
    }
    

    const startTimeBody = (rowData: Travel) => {
        return <div className="block">
            <div className="text-center">{formatTimeString(rowData.startTime)}  {formatTimeString(rowData.endTime)}</div>
            <Badge value={rowData.duration} severity="info" size="normal" className="flex justify-content-center"/>
            </div>
    }

    const routeSelectedItemTemplate = (route: Route) => {
        
        return (
            route ? <LightRouteDisplayTemplate route={route} titleFontSize={1} contentFontSize={2} /> : <div>Choisissez un itinéraire</div>
        );
    }

    const travelDataViewHeader = () => {
        return <div className="flex gap-3">
            <Dropdown 
                loading={routeQLoading || routeQIsFetching} 
                value={selectedRoute} id='route' itemTemplate={routeItemTemplate} 
                valueTemplate={routeSelectedItemTemplate}  scrollHeight="400px" 
                onChange={(e) => setSelectedRoute(e.value)} options={routesData} optionLabel="name" 
                placeholder="Choisissez un itinéraire" className="w-full" />

            <Calendar
                value={dateRange} 
                onChange={(e) => setDateRange(e.value)} 
                dateFormat="dd/mm/yy"
                minDate={new Date()}
                locale='fr' 
                selectionMode="range" 
                readOnlyInput hideOnRangeSelection
                className='w-full'
                />
        </div>
    }


    const itemTemplate = (detailedTravel: DetailedTravel, index: number) => {
        return <div className={classNames("hover:bg-primary-50", {"bg-primary-50": detailedTravel.travel.id === selectedDetailedTravel?.travel.id})} onClick={() => setSelectedDetailedTravel(detailedTravel)} >
                <div className="flex cursor-pointer border-round-xl py-3" >
                    <div className="flex-grow-1 overflow-hidden text-overflow-ellipsis">
                        <FullRouteDisplayTemplate route={detailedTravel.travel.route} titleFontSize={2} contentFontSize={1}/>
                    </div>
                    <div className="mr-3">
                        <div>{startDateBody(detailedTravel.travel)}</div>
                        <div>{startTimeBody(detailedTravel.travel)}</div>
                    </div>
                </div>
                <Divider pt={{
                        root: {
                            className: "m-0"
                        }
                    }}/>
            </div>
    }

    const listTemplate = (detailedTravels: DetailedTravel[]) : ReactNode[] => {
        if (!detailedTravels || detailedTravels.length === 0) return [<div></div>];

        let list = detailedTravels.map((detailedTravel, index) => {
            return itemTemplate(detailedTravel, index)
        });

        return list;
    }


    const fillingRateBody = (fillRates: DetailedTravel) => {
        return <div className="inline-block">
            <MeterGroup values={fillRatesData(fillRates.fillRates)} />
            </div>
    }


    return <Card title={<CardHeader title="Gestion des voyages" />} className='shadow-none' style={{backgroundColor: "transparent"}}  pt={{
        content: {
          //className: "p-0"
        }}}>

        {/*
        <div className="flex flex-column align-items-center align-content-center justify-content-center" style={{minHeight : "80vh"}} >
            <i className="pi pi-map font-bold text-5xl"/> 
            <div className="font-bold text-5xl">En cours de developpement ...</div>
            <div className="text-xl">revenez plus tard</div>
        </div>
        */}

        <div className="grid">
            <div className="col-12 xl:col-7">
                <div className="card zoomin animation-duration-500 mx-2 p-3 h-full">
                    <DataView 
                        value={dataToDisplay()} 
                        listTemplate={listTemplate}
                        header= {travelDataViewHeader()}
                        paginator 
                        rows={3} />
                    {/*
                    <DataTable scrollable = {false}
                        className=""
                        value={dataToDisplay()} tableStyle={{  }}
                        paginator rows={3} 
                        loading={travelQLoading}  loadingIcon={loader}>
                        <Column field="startDate" header="Itinéraire" body={routeBody} className="cursor-pointer overflow-x-auto common-scroll"/>
                        <Column field="startDate" header="Date" body={startDateBody} className="cursor-pointer"/>
                        <Column field="startTime" header="Horaire" body={startTimeBody} className="cursor-pointer" />
                        {<Column field="startTime" header="Remplissage" body={fillingRateBody} className="w-20rem"/>}
                    </DataTable>
                    */}
                </div>
            </div>

            <div className="col-12 xl:col-5">
                <div className="flex flex-column h-full gap-3">
                    <div className="card w-full h-full mb-0">
                    <div className="font-light font-semibold text-xl pb-5">Détail</div>
                    </div>
                    <div className="card w-full h-full mb-0">
                        <div className="font-light font-semibold text-xl pb-5">Statistiques</div>
                        <div>{selectedDetailedTravel && fillingRateBody(selectedDetailedTravel)}</div>
                    </div>
                </div>

            </div>

        </div>

        <div className="card my-2">
            <div className="font-light font-semibold text-xl pb-5">Passagers</div>
        </div>

    </Card>

}