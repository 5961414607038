import { useGetTravelsInPlanningQuery } from "../../service/travelMgrApi";
import { Planning, Travel } from "../../service/type";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDateString, formatTimeString } from "../../DateUtils";
import { Badge } from "primereact/badge";
import { MeterGroup } from 'primereact/metergroup';
import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FullRouteDisplayTemplate } from "../route/RouteDisplayTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { ProgressSpinner } from "primereact/progressspinner";
import { classNames } from "primereact/utils";
import { PriceComponent } from "./Price";


export const ScheduleDetails = () => {
    
    const {planningId} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const planning = (location.state.planning as Planning)

    const fullFill = [
        { label: 'Adulte', color: '#34d399', value: 90 },
        { label: 'Enfant', color: '#fbbf24', value: 10 },
    ];

    const notFullFill = [
        { label: 'Adulte', color: '#34d399', value: 50 },
        { label: 'Enfant', color: '#fbbf24', value: 5 },
    ]


    const { data: travelQData, error: travelQError, isLoading: travelQLoading, status: travelQStatus, isError: travelQIsError, isFetching: travelQIsFetching } = useGetTravelsInPlanningQuery({planningId: planningId})
    

    const dataToDisplay = useCallback(() => {
        return (travelQData ? [...travelQData].sort((a, b) => (a.startDate+a.startTime).localeCompare(b.startDate+b.startTime)) : [])
    }, [travelQData])

    const startDateBody = (rowData: Travel) => {
        return <div>
            <p className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{formatDateString(rowData.startDate)}</p>
            </div>
    }

    const startTimeBody = (rowData: Travel) => {
        return <div className="inline-block">
            <div className="text-center">{formatTimeString(rowData.startTime)}  {formatTimeString(rowData.endTime)}</div>
            <Badge value={rowData.duration} severity="info" size="normal" className="flex justify-content-center"/>
            </div>
    }

    const saleBody = (rowData: Travel) => {
        return <div className={classNames("inline-block cursor-pointer", )}>
            <Badge 
                value={
                    <span className="flex gap-2 align-items-center">
                        <span className={classNames("pi", {'pi-lock-open' : rowData.sellAllowed}, {'pi-lock' : !rowData.sellAllowed})}/>
                        {rowData.sellAllowed? "ouverte" : "fermée"}
                    </span>} 
                severity={!rowData.sellAllowed ? 'danger': 'success'} 
                size="normal" 
                className="flex justify-content-center"/>
            </div>
    }

    const ticketStatusBody = (rowData: Travel) => {
        return <div className="inline-block">
            <Badge 
                value={
                    <span className="flex gap-2 align-items-center">
                        <span className={classNames("pi", {'pi-circle-fill' : rowData.ticketAvailable}, {'pi-times-circle' : !rowData.ticketAvailable})}/>
                        {rowData.ticketAvailable? 'disponible' : "complet"}
                    </span>} 
                severity={!rowData.ticketAvailable ? 'danger': 'success'} 
                size="normal" 
                className="flex justify-content-center"/>

            </div>
    }

    const fillingRateBody = (rowData: Travel) => {
        const dispo = rowData.startTime.includes("7") || rowData.startTime.includes("14")
        return <div className="inline-block">
            <MeterGroup values={dispo ? notFullFill : fullFill} />
            </div>
    }

    const tableHeader = <div className="flex flex-wrap flex-row pb-3">
        <div 
              className='flex hover:bg-primary cursor-pointer align-content-center align-items-center justify-content-center border-circle mr-1' 
              style={{width : "2.25rem", height: "2.25rem"}}
              onClick={(e)=> navigate(-1)}
              >
            <FontAwesomeIcon icon={faChevronLeft} className="font-bold text-2xl" />
        </div>
        <div className="overflow-hidden">
            <div className="text-primary font-bold text-2xl white-space-nowrap overflow-hidden text-overflow-ellipsis">{planning.route.name}</div>
            <small>du {formatDateString(planning.startDate)} au {formatDateString(planning.endDate)}</small>
        </div>
        <div className="flex flex-wrap gap-2 flex-grow-1 align-items-center align-content-center justify-content-center">
            {/*planning.prices.map(price => <PriceComponent price={price} />)*/}
        </div>
        <div className="overflow-x-auto common-scroll">
            <FullRouteDisplayTemplate route={planning.route} contentFontSize={1} titleFontSize={2} hideTitle={true}/>
        </div>
    </div>

    const loader = <div className="text-center align-content-center" style={{backgroundColor: "transparent", height: "80vh"}}>
            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" animationDuration=".5s" />
        </div>

    return (

        <div className="m-0 card zoomin animation-duration-500">
            {tableHeader}
            <DataTable 
                value={dataToDisplay()} tableStyle={{ minWidth: '50rem' }} 
                paginator rows={5} scrollable scrollHeight="85vh" 
                loading={travelQLoading}  loadingIcon={loader}>
                <Column field="startDate" header="Date" body={startDateBody} className="w-4rem"/>
                <Column field="startTime" header="Horaire" body={startTimeBody} className="w-10rem" />
                <Column field="startTime" header="Mise en vente" body={saleBody} className="w-10rem" />
                <Column field="startTime" header="Disponibilité des billets" body={ticketStatusBody} className="w-10rem" />
                {/*<Column field="startTime" header="Remplissage" body={fillingRateBody} className="w-20rem"/>*/}
            </DataTable>
        </div>

    )

}