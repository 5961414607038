import { differenceInHours, differenceInMinutes, format, isToday, isTomorrow, isYesterday, parse, setDefaultOptions } from "date-fns"

import { fr } from 'date-fns/locale'



const API_DATE_FORMAT = 'yyyyMMdd'
const API_TIME_FORMAT = 'HH:mm'

setDefaultOptions({ locale: fr })

export const parseDateString = (dateString: string) => {
    return parse(dateString, API_DATE_FORMAT, new Date())
}

export const parseTimeString = (timeString: string) => {
    return parse(timeString, API_TIME_FORMAT, new Date())
}


export const formatDateString = (dateString: string) => {
    return custumFormatDate(parseDateString(dateString))
}

export const custumFormatDate = (date: Date) => {
    return format(date, "EEE dd MMM yyyy")
}

export const custumFormatDateTime = (date: Date) => {
    return format(date, "EEE dd MMM yyyy HH:mm")
}

export const custumFormatDateTimeRelative = (date: Date) => {
    
    if(isTomorrow(date)) {
        return "demain à " +custumSimpleFormatTime(date)
    }

    if(isToday(date)) {
        return "aujourdh'ui à "+ custumSimpleFormatTime(date)
    }

    if(isYesterday(date)) {
        return 'hier à '+custumSimpleFormatTime(date)
    }

    return format(date, "EEE dd MMM yyyy HH:mm")
}

export const formatTimeString = (timeString: string) => {
    return custumFormatTime(parseTimeString(timeString))
}

export const custumFormatTime = (date: Date) => {
    return format(date, "HH'H':mm")
}

export const custumSimpleFormatTime = (date: Date) => {
    return format(date, "HH:mm")
}


export const printDuration = (fromDateString: string, startTimeString: string, endDateString: string, endTimeString: string) => {
    const startTime = parseTimeString(startTimeString)
    const endTime = parseTimeString(endTimeString)

    const startDate = parseDateString(fromDateString)
    const endDate = parseDateString(endDateString)

    startDate.setHours(startTime.getHours(), startTime.getMinutes())
    endDate.setHours(endTime.getHours(), endTime.getMinutes())

    const hours = differenceInHours(endDate, startDate)


    const startTimes = new Date().setHours(0, startTime.getMinutes())
    const endTimes = new Date().setHours(0, endTime.getMinutes())

    const minutes = differenceInMinutes(endTimes, startTimes)

    const hoursString = hours >0 ? hours+ " heures" : ""
    const minutesString = minutes >0 ? minutes+ " minutes " : ""

    return  hoursString+" "+minutesString
}

export const atStartOfDay = (date: Date) : Date => {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    return newDate
}

export const atEndOfDay = (date: Date) : Date => {
    const newDate = new Date(date)
    newDate.setHours(23, 59, 59, 59)
    return newDate
}